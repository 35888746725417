<template lang="">
  <div>
    <!-- SECTION Filter -->
    <BCard
      header-class="py-1"
      body-class="pb-1"
    >
      <template #header>
        <div class="font-weight-bolder text-uppercase">
          {{ $t('filter') }}
        </div>
      </template>
      <BRow>
        <!-- ANCHOR startFlightDate -->
        <BCol
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            class="w-100 mb-0"
            :label="$t('reservation.bookingStatistic.startFlightDate')"
            label-for="range-date"
          >
            <b-input-group
              id="range-date"
              class=""
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr
                v-model="startFlightDate"
                class="form-control form-control-md"
                :config="{
                  dateFormat: 'Y-m-d',
                  altFormat: 'd-m-Y',
                  class: 'form-control-md',
                  altInput: true,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                :placeholder="$t('reservation.bookingStatistic.startFlightDate')"
              />

            </b-input-group>
          </b-form-group>
        </BCol>

        <!-- ANCHOR endFlightDate -->
        <BCol
          md="6"
          cols="12"
          lg="3"
          class="mt-50 mt-md-0"
        >
          <b-form-group
            :label="$t('reservation.bookingStatistic.endFlightDate')"
            label-for="range-date"
            class="w-100 mb-0"
          >
            <b-input-group
              id="range-date"
              class=""
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-prepend>
              <flat-pickr
                v-model="endFlightDate"
                class="form-control form-control-md"
                :config="{
                  dateFormat: 'Y-m-d',
                  altFormat: 'd-m-Y',
                  class: 'form-control-md',
                  altInput: true,
                  minDate: startFlightDate,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                :placeholder="$t('reservation.bookingStatistic.endFlightDate')"
              />

            </b-input-group>
          </b-form-group>
        </BCol>

        <!-- ANCHOR source -->
        <BCol
          md="6"
          cols="12"
          lg="3"
          class="mt-50 mt-lg-0"
        >
          <b-form-group
            :label="$t('reservation.bookingStatistic.source')"
            label-for="source"
            class="w-100 mb-0"
          >
            <!-- :label="$t('saleReport.columns.source')" -->
            <v-select
              v-model="source"
              class="w-100"
              style="font-size: 1rem;"
              :options="distributorsList"
              label="label"
              clearable
              :placeholder="$t('saleReport.columns.source')"
              :reduce="val => val.value"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </BCol>

        <!-- ANCHOR - BUTTONs -->
        <BCol
          cols="12"
          md="6"
          lg="3"
          class="mt-50 mt-lg-0 d-flex-center justify-content-md-end gap-2"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="font-weight-bolder p-75 text-nowrap"
            variant="outline-warning"
            :disabled="isDisabledButtons"
            @click="submitSearch"
          >
            <feather-icon
              v-if="!isMobileView"
              icon="SearchIcon"
              class="text-warning"
            />
            <span
              class="align-middle text-warning font-weight-bolder"
            >
              {{ $t('search') }}
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="font-weight-bolder p-75 text-nowrap"
            variant="outline-success"
            :disabled="isDisabledButtons"
            @click="clearFilter"
          >
            <feather-icon
              v-if="!isMobileView"
              icon="SearchIcon"
              class="text-success"
            />
            <span
              class="align-middle text-success font-weight-bolder"
            >
              {{ $t('reservation.bookingStatistic.findAll') }}
            </span>
          </b-button>
        </BCol>
      </BRow>
    </BCard>
    <!-- !SECTION -->

    <!-- SECTION Card ket qua -->
    <BCard header-class="py-1">
      <template #header>
        <div class="font-weight-bolder text-uppercase">
          {{ $t('reservation.bookingStatistic.title') }}
        </div>
      </template>

      <BTable
        style="max-height: calc(100vh - 400px); height: calc(100vh - 400px)"
        :sticky-header="true"
        class="position-relative"
        :items="resultArray"
        responsive
        striped
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        small
        :busy="loading"
      >
        <template #table-busy>
          <div class="d-flex-center text-dark my-2 gap-2">
            <b-spinner class="align-middle" />
            <strong>{{ $t('loading') }}</strong>
          </div>
        </template>

        <template
          v-for="column in tableColumns"
          v-slot:[`head(${column})`]=""
        >
          <span
            :key="column"
            class="text-dark text-nowrap"
          >
            {{ $t(`reservation.bookingStatistic.${column}`) }}
          </span>
        </template>

        <template #cell(no)="data">
          <div>
            {{ data.index + 1 }}
          </div>
        </template>

        <template #cell(bookingCode)="{ item }">
          <div style="max-width: 400px">
            <div v-html="item.metadata.map(({bookingCode }) => `<code class='font-weight-bolder'>${bookingCode}</code>`).join(', ')" />
            <div v-if="item.total > 1">
              {{ $t('reservation.bookingStatistic.totalBooking') }}: <span class="font-weight-bolder">{{ item.total }}</span>
            </div>
          </div>
        </template>

        <template #cell(itineraries)="{ item }">
          <div>
            <span>{{ item.airports.replaceAll(' | ', ', ') }}</span>
          </div>
        </template>

        <template #cell(flightDate)="{ item }">
          <div>
            {{ convertISODateTime(item.flightDate).date }}
          </div>
        </template>

        <template #cell(detail)="data">
          <b-button
            variant="flat-dark"
            class="p-50 rounded-circle"
            @click="data.toggleDetails()"
          >
            <feather-icon
              :class="{ 'rotate-180': !data.detailsShowing, 'rotate-transition': true }"
              icon="ChevronUpIcon"
              size="16"
            />
          </b-button>
        </template>

        <template #row-details="{item}">
          <BTable
            class="position-relative"
            :items="item.metadata"
            responsive
            bordered
            caption-top
            :fields="['no','bookingCode', 'paxLists', 'totalPassenger']"
            primary-key="id"
            show-empty
            :empty-text="$t('noMatchingResult')"
            no-border-collapse
            small
            :busy="loading"
          >
            <template
              v-for="column in ['no','bookingCode', 'paxLists', 'totalPassenger']"
              v-slot:[`head(${column})`]=""
            >
              <span
                :key="column"
                class="text-dark text-nowrap"
              >
                {{ $t(`reservation.bookingStatistic.${column}`) }}
              </span>
            </template>
            <template #cell(no)="{ index }">
              <div>
                {{ index + 1 }}
              </div>
            </template>

            <template #cell(bookingCode)="data">
              <code class="font-weight-bolder">{{ data.item.bookingCode }}</code>
            </template>

            <template #cell(paxLists)="data">
              <div
                v-for="(paxItem, paxIndex) of data.item.paxLists"
                :key="paxIndex"
              >
                {{ paxItem }}
              </div>
            </template>
          </BTable>
        </template>

      </BTable>

      <!-- ANCHOR: Table Footer -->
      <b-row class=" d-flex-center justify-content-md-between">
        <b-col
          cols="12"
          md="auto"
          class="d-flex align-items-center justify-content-center mb-50 mb-md-0"
        >
          <span class="font-weight-bolder">
            {{ $t('paginationText.showing') }}
          </span>
          <v-select
            v-model="sizePerPage"
            :options="sizePerPageLgOptions"
            :clearable="false"
            :append-to-body="true"
            :calculate-position="withPopper"
            class="per-page-selector d-inline-block mx-50"
          >
            <template #option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #selected-option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
          <span class="font-weight-bolder">
            {{ $t('paginationText.from') }} {{ fromItem }} {{ $t('paginationText.to') }} {{ toItem }} {{ $t('paginationText.outOf') }} {{ totalItems }}
          </span>
        </b-col>

        <b-col
          cols="12"
          md="auto"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="sizePerPage"
            first-number
            last-number
            class="mb-0 mt-25 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>

    </BCard>
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BInputGroup, BInputGroupPrepend, BFormGroup, BTable, BSpinner, BPagination,
} from 'bootstrap-vue'
import {
  ref, computed, watch, nextTick,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import moment from 'moment'
import { createPopper } from '@popperjs/core'

import { apiReservation } from '@/api'
import {
  distributorsList,
  sizePerPageLgOptions,
} from '@/constants/selectOptions'

import { convertISODateTime } from '@core/utils/filter'
import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BTable,
    BSpinner,
    BPagination,
    vSelect: () => import('vue-select'),
    flatPickr: () => import('vue-flatpickr-component'),
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = width || '200px'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup() {
    const { toastError } = useToast()
    const DAY_DIFF_DEFAULT = 30

    // ANCHOR - DEFINE FILTERS
    const startFlightDate = ref(moment().format('YYYY-MM-DD'))
    const endFlightDate = ref(moment().add(DAY_DIFF_DEFAULT, 'days').format('YYYY-MM-DD'))
    const source = ref()
    const resultArray = ref([])
    const currentPage = ref(1)
    const sizePerPage = ref(20)
    const loading = ref(false)
    const totalItems = ref(0)

    function submitSearch() {
      loading.value = true
      const params = {
        startFlightDate: startFlightDate.value || undefined,
        endFlightDate: endFlightDate.value || undefined,
        source: source.value || undefined,
        page: currentPage.value || 1,
        size: sizePerPage.value || 20,
      }
      apiReservation.bookingStatistic(params)
        .then(res => {
          resultArray.value = res.data.items
          totalItems.value = res.data.total
        })
        .catch(() => {
          toastError('Lỗi tìm kiếm')
        })
        .finally(() => {
          loading.value = false
        })
    }

    const fromItem = computed(() => sizePerPage.value * (currentPage.value - 1) + 1)
    const toItem = computed(() => sizePerPage.value * (currentPage.value))

    watch([currentPage, sizePerPage], () => {
      submitSearch()
    })

    submitSearch()

    function clearFilter() {
      startFlightDate.value = undefined
      endFlightDate.value = undefined
      source.value = undefined
      nextTick(() => { submitSearch() })
    }

    const isDisabledButtons = computed(() => (!startFlightDate.value && !endFlightDate.value) || (moment(endFlightDate.value) < moment(startFlightDate.value)))

    watch(() => startFlightDate.value, () => {
      if (startFlightDate.value && (endFlightDate.value < startFlightDate.value)) {
        endFlightDate.value = moment(startFlightDate.value).add(DAY_DIFF_DEFAULT, 'days').format('YYYY-MM-DD')
      }
    })
    // ANCHOR Result
    const tableColumns = ref(['no', 'source', 'itineraries', 'flightDate', 'flightNumber', 'bookingCode', 'detail'])

    return {
      // variable filter
      startFlightDate,
      endFlightDate,
      source,
      distributorsList,

      loading,
      isDisabledButtons,

      // result
      resultArray,
      tableColumns,

      // methods
      submitSearch,
      clearFilter,

      // extras
      Vietnamese,
      removeAccentsUpperCaseFormatter,
      convertISODateTime,
      sizePerPageLgOptions,
      currentPage,
      sizePerPage,
      totalItems,
      fromItem,
      toItem,
    }
  },
}
</script>

<style lang="">

</style>
