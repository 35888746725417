var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BCard', {
    attrs: {
      "header-class": "py-1",
      "body-class": "pb-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font-weight-bolder text-uppercase"
        }, [_vm._v(" " + _vm._s(_vm.$t('filter')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('BRow', [_c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0",
    attrs: {
      "label": _vm.$t('reservation.bookingStatistic.startFlightDate'),
      "label-for": "range-date"
    }
  }, [_c('b-input-group', {
    attrs: {
      "id": "range-date"
    }
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  })], 1), _c('flat-pickr', {
    staticClass: "form-control form-control-md",
    attrs: {
      "config": {
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        class: 'form-control-md',
        altInput: true,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      },
      "placeholder": _vm.$t('reservation.bookingStatistic.startFlightDate')
    },
    model: {
      value: _vm.startFlightDate,
      callback: function callback($$v) {
        _vm.startFlightDate = $$v;
      },
      expression: "startFlightDate"
    }
  })], 1)], 1)], 1), _c('BCol', {
    staticClass: "mt-50 mt-md-0",
    attrs: {
      "md": "6",
      "cols": "12",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0",
    attrs: {
      "label": _vm.$t('reservation.bookingStatistic.endFlightDate'),
      "label-for": "range-date"
    }
  }, [_c('b-input-group', {
    attrs: {
      "id": "range-date"
    }
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  })], 1), _c('flat-pickr', {
    staticClass: "form-control form-control-md",
    attrs: {
      "config": {
        dateFormat: 'Y-m-d',
        altFormat: 'd-m-Y',
        class: 'form-control-md',
        altInput: true,
        minDate: _vm.startFlightDate,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      },
      "placeholder": _vm.$t('reservation.bookingStatistic.endFlightDate')
    },
    model: {
      value: _vm.endFlightDate,
      callback: function callback($$v) {
        _vm.endFlightDate = $$v;
      },
      expression: "endFlightDate"
    }
  })], 1)], 1)], 1), _c('BCol', {
    staticClass: "mt-50 mt-lg-0",
    attrs: {
      "md": "6",
      "cols": "12",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0",
    attrs: {
      "label": _vm.$t('reservation.bookingStatistic.source'),
      "label-for": "source"
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "options": _vm.distributorsList,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('saleReport.columns.source'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.source,
      callback: function callback($$v) {
        _vm.source = $$v;
      },
      expression: "source"
    }
  })], 1)], 1), _c('BCol', {
    staticClass: "mt-50 mt-lg-0 d-flex-center justify-content-md-end gap-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "font-weight-bolder p-75 text-nowrap",
    attrs: {
      "variant": "outline-warning",
      "disabled": _vm.isDisabledButtons
    },
    on: {
      "click": _vm.submitSearch
    }
  }, [!_vm.isMobileView ? _c('feather-icon', {
    staticClass: "text-warning",
    attrs: {
      "icon": "SearchIcon"
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle text-warning font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('search')) + " ")])], 1), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "font-weight-bolder p-75 text-nowrap",
    attrs: {
      "variant": "outline-success",
      "disabled": _vm.isDisabledButtons
    },
    on: {
      "click": _vm.clearFilter
    }
  }, [!_vm.isMobileView ? _c('feather-icon', {
    staticClass: "text-success",
    attrs: {
      "icon": "SearchIcon"
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle text-success font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingStatistic.findAll')) + " ")])], 1)], 1)], 1)], 1), _c('BCard', {
    attrs: {
      "header-class": "py-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font-weight-bolder text-uppercase"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingStatistic.title')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('BTable', {
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "calc(100vh - 400px)",
      "height": "calc(100vh - 400px)"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.resultArray,
      "responsive": "",
      "striped": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "busy": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, _vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn() {
          return [_c('span', {
            key: column,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.bookingStatistic.".concat(column))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('div', [_vm._v(" " + _vm._s(data.index + 1) + " ")])];
      }
    }, {
      key: "cell(bookingCode)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticStyle: {
            "max-width": "400px"
          }
        }, [_c('div', {
          domProps: {
            "innerHTML": _vm._s(item.metadata.map(function (_ref2) {
              var bookingCode = _ref2.bookingCode;
              return "<code class='font-weight-bolder'>".concat(bookingCode, "</code>");
            }).join(', '))
          }
        }), item.total > 1 ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingStatistic.totalBooking')) + ": "), _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(item.total))])]) : _vm._e()])];
      }
    }, {
      key: "cell(itineraries)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_c('span', [_vm._v(_vm._s(item.airports.replaceAll(' | ', ', ')))])])];
      }
    }, {
      key: "cell(flightDate)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.flightDate).date) + " ")])];
      }
    }, {
      key: "cell(detail)",
      fn: function fn(data) {
        return [_c('b-button', {
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark"
          },
          on: {
            "click": function click($event) {
              return data.toggleDetails();
            }
          }
        }, [_c('feather-icon', {
          class: {
            'rotate-180': !data.detailsShowing,
            'rotate-transition': true
          },
          attrs: {
            "icon": "ChevronUpIcon",
            "size": "16"
          }
        })], 1)];
      }
    }, {
      key: "row-details",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('BTable', {
          staticClass: "position-relative",
          attrs: {
            "items": item.metadata,
            "responsive": "",
            "bordered": "",
            "caption-top": "",
            "fields": ['no', 'bookingCode', 'paxLists', 'totalPassenger'],
            "primary-key": "id",
            "show-empty": "",
            "empty-text": _vm.$t('noMatchingResult'),
            "no-border-collapse": "",
            "small": "",
            "busy": _vm.loading
          },
          scopedSlots: _vm._u([_vm._l(['no', 'bookingCode', 'paxLists', 'totalPassenger'], function (column) {
            return {
              key: "head(".concat(column, ")"),
              fn: function fn() {
                return [_c('span', {
                  key: column,
                  staticClass: "text-dark text-nowrap"
                }, [_vm._v(" " + _vm._s(_vm.$t("reservation.bookingStatistic.".concat(column))) + " ")])];
              },
              proxy: true
            };
          }), {
            key: "cell(no)",
            fn: function fn(_ref6) {
              var index = _ref6.index;
              return [_c('div', [_vm._v(" " + _vm._s(index + 1) + " ")])];
            }
          }, {
            key: "cell(bookingCode)",
            fn: function fn(data) {
              return [_c('code', {
                staticClass: "font-weight-bolder"
              }, [_vm._v(_vm._s(data.item.bookingCode))])];
            }
          }, {
            key: "cell(paxLists)",
            fn: function fn(data) {
              return _vm._l(data.item.paxLists, function (paxItem, paxIndex) {
                return _c('div', {
                  key: paxIndex
                }, [_vm._v(" " + _vm._s(paxItem) + " ")]);
              });
            }
          }], null, true)
        })];
      }
    }], null, true)
  }), _c('b-row', {
    staticClass: " d-flex-center justify-content-md-between"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center mb-50 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.showing')) + " ")]), _c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "options": _vm.sizePerPageLgOptions,
      "clearable": false,
      "append-to-body": true,
      "calculate-position": _vm.withPopper
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  }), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.from')) + " " + _vm._s(_vm.fromItem) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.toItem) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.totalItems) + " ")])], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-25 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalItems,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }